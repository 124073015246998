import { getSubDomain } from '@guider-global/front-end-utils';
import { useOrganizationPrograms } from '@guider-global/sanity-hooks';
import { EProgramVariation } from '@guider-global/shared-types';
import { GroupsProvider } from 'containers/Groups';
import {
  GroupMembershipPage,
  ProgramManageGuidePage,
  ProgramMembershipPage,
  ProgramRegistrationAboutYouPage,
  ProgramRegistrationPage,
} from 'pages';
import { useLocation, useParams } from 'react-router-dom';

interface ProgramsWrapperProps {
  enableProgramsV2: boolean;
}

export function ProgramsWrapper({ enableProgramsV2 }: ProgramsWrapperProps) {
  const { programSlug = '' } = useParams<{
    programSlug: string;
  }>();

  const { pathname } = useLocation();

  const organizationSlug = getSubDomain();
  const { programs } = useOrganizationPrograms({ organizationSlug });

  const program = programs?.find(
    (program) => program?.metadata?.id?.current === programSlug,
  );
  const programVariation = program?.program_details?.program_variation;

  if (programVariation === EProgramVariation.Group) {
    return (
      <GroupsProvider>
        <GroupMembershipPage />
      </GroupsProvider>
    );
  }

  if (enableProgramsV2) {
    return <ProgramMembershipPage />;
  } else {
    return pathname.includes('manage') ? (
      <ProgramManageGuidePage />
    ) : (
      <ProgramRegistrationPage>
        <ProgramRegistrationAboutYouPage />
      </ProgramRegistrationPage>
    );
  }
}
